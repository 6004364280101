import { FC } from 'react';
import IconProps from './IconProps';

const SorterIcon: FC<IconProps & { direction: 'asc' | 'desc' }> = (props) => {
  const { onClick, className, direction } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="11" height="22" transform="translate(0.00292969 0.679688)" fill="white" fillOpacity="0.01" />
      <path
        d="M4.98528 18.3384L1.68803 14.5696C1.29891 14.1261 1.61516 13.4297 2.20572 13.4297H8.80022C8.93239 13.4296 9.06179 13.4676 9.17292 13.5391C9.28405 13.6106 9.37221 13.7127 9.42684 13.833C9.48147 13.9534 9.50024 14.0869 9.48092 14.2177C9.4616 14.3484 9.405 14.4708 9.31791 14.5703L6.02066 18.3378C5.95613 18.4116 5.87654 18.4708 5.78726 18.5113C5.69797 18.5519 5.60103 18.5729 5.50297 18.5729C5.4049 18.5729 5.30797 18.5519 5.21868 18.5113C5.12939 18.4708 5.04981 18.4116 4.98528 18.3378V18.3384Z"
        fill={direction === 'asc' ? 'currentColor' : '#3E2234'}
      />
      <path
        d="M4.98528 6.01958L1.68803 9.78777C1.29891 10.2326 1.61516 10.9283 2.20572 10.9283H8.80022C8.93239 10.9284 9.06179 10.8905 9.17292 10.8189C9.28405 10.7474 9.37221 10.6453 9.42684 10.525C9.48147 10.4046 9.50024 10.2711 9.48092 10.1403C9.4616 10.0096 9.405 9.88718 9.31791 9.78777L6.02066 6.02027C5.95613 5.94642 5.87654 5.88724 5.78726 5.84669C5.69797 5.80614 5.60103 5.78516 5.50297 5.78516C5.4049 5.78516 5.30797 5.80614 5.21868 5.84669C5.12939 5.88724 5.04981 5.94642 4.98528 6.02027V6.01958Z"
        fill={direction === 'asc' ? '#3E2234' : 'currentColor'}
      />
    </svg>
  );
};

export default SorterIcon;
